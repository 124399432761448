import { DeepPartial, AppConfig } from "../../../../src/app/interfaces/app-config";

export const projectConfig: DeepPartial<AppConfig> = {
    // Ejemplo
     pages: {
         login: {
             title: 'Iniciar sesión',
         },
         users: {
            titlePlural: 'Usuarios',
            titleSingular: 'Usuario',
            form: {
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                image: {
                    label: 'Imagen',
                    placeholder: 'Selecciona una imagen'
                },
                rol: {
                    label: 'Asignar rol',
                    placeholder: 'Selecciona....'
                },
                agency: {
                    label: 'Agencia',
                    placeholder: 'Seleccionar agencia....'
                }
            },
            form_filter:{
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
            },
        },
        profile: {
            titlePlural: 'Registros',
            titleSingular: 'Registro',
            form_filter: {
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir nombre'
                },
                lastName:{
                    label: 'Apellido',
                    placeholder: 'Escribir apellido'
                },
                email:{
                    label: 'Correo electrónico',
                    placeholder: 'Escribir correo electrónico'
                },
                numberCi:{
                    label: 'Numero de carnet',
                    placeholder: 'Escribir numero carnet'
                },
                initial_date:{
                    label: 'Fecha inicio',
                    placeholder: 'Escribir...'
                },
                end_date:{
                    label: 'Fecha fin',
                    placeholder: 'Escribir...'
                },
                city:{
                    label: 'Ciudad',
                    placeholder: 'Escribir...'
                },
                event:{
                    label: 'Eventos',
                    placeholder: 'Seleccionar...'
                },
            }
        },
        historyTickets: {
            titlePlural: 'Historial',
            titleSingular: 'Historial',
            form:{},
            form_filter: {
                search: {
                    label: 'Buscar código',
                    placeholder: 'Escribir código...'
                },
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir nombre...'
                },
                lastName:{
                    label: 'Apellido',
                    placeholder: 'Escribir apellido...'
                },
                email:{
                    label: 'Correo electrónico',
                    placeholder: 'Escribir correo electrónico...'
                },
                numberCi:{
                    label: 'Numero de carnet',
                    placeholder: 'Escribir numero de carnet...'
                },
                initial_date:{
                    label: 'Fecha inicio',
                    placeholder: 'Escribir fecha inicio...'
                },
                end_date:{
                    label: 'Fecha fin',
                    placeholder: 'Escribir fecha fin...'
                },
                event:{
                    label: 'Eventos',
                    placeholder: 'Seleccionar evento...'
                },

            }
        },
        deliveryTicket: {
            titlePlural: 'Entregas de Tickets',
            titleSingular: 'Entrega de Ticket',
            form:{
                agency:{
                    label: 'Tier',
                    placeholder: 'Escribir...'
                },
                ci_number:{
                    label: 'CI',
                    placeholder: 'Escribir...'
                },
                quantity:{
                    label: 'Cantidad',
                    placeholder: 'Escribir...'
                },
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir...'
                },

            },
            form_filter:{
                search: {
                    label: 'Buscar código',
                    placeholder: 'Escribir código...'
                },
            }
        },

        roles: {
            titlePlural: 'Roles',
            titleSingular: 'Rol',
            form: {
                name: {
                    label: 'Nombre del rol',
                    placeholder: 'Ingresar nombre...'
                },
                description: {
                    label: 'Descripción',
                    placeholder: 'Ingresa, descripción y alcance del rol...'
                },
            },
            form_filter:{
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                cellphone: {
                    label: 'Celular',
                    placeholder: 'Ingresa tu número de celular'
                },
                initial_date: {
                    label: 'Fecha inicio',
                    placeholder: 'Ingresa fecha inicio'
                },
                end_date: {
                    label: 'Fecha fin',
                    placeholder: 'Ingresa fecha fin'
                },
            }
        },
        permissions: {
            titlePlural: 'Permisos',
            titleSingular: 'Permiso',
            form: {
                name: {
                    label: 'Nombre del permisio',
                    placeholder: 'Ingresar nombre...'
                },
                description: {
                    label: 'Descripción',
                    placeholder: 'Ingresa, descripción y alcance del rol...'
                },
            },
            form_filter:{
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                cellphone: {
                    label: 'Celular',
                    placeholder: 'Ingresa tu número de celular'
                },
                initial_date: {
                    label: 'Fecha inicio',
                    placeholder: 'Ingresa fecha inicio'
                },
                end_date: {
                    label: 'Fecha fin',
                    placeholder: 'Ingresa fecha fin'
                },
            }
        },
        assistance: {
           titlePlural: 'Asistencias',
           titleSingular: 'Asistencia',
           form:{},
       },
     },

    menu: [
        {
            headTitle1: "General",
            permissions: 'default',
        },
        {
            id: 1,
            level: 1,
            path: '/account',
            title: "Mi Cuenta",
            icon: "home",
            type: "link",
            permissions: 'default',
        },
        {
            headTitle1: "Admin",
            permissions: 'default',
        },
        {
            id: 2,
            level: 1,
            path: '/users',
            title: "Usuarios",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-users',
        },
        {
            id: 3,
            level: 1,
            path: '/profile',
            title: "Pre-Registros",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-profiles',
        },
        {
            id: 4,
            level: 1,
            path: '/customer',
            title: "customer",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-customers',
        },
        {
            headTitle1: "Admin tickets",
            permissions: 'default',
        },
        {
            id: 5,
            level: 1,
            path: 'history-tickets',
            title: "Tickets",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-history_tickets',
        },
        {
            id: 5,
            level: 1,
            path: 'assistance',
            title: "Reporte de asistencia",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-assistance',
        },
        {
            headTitle1: "Entregas",
            permissions: 'default',
        },        
        {
            id: 6,
            level: 1,
            path: 'delivery-ticket',
            title: "Entrega de tickets",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-delivery_tickets',
        },        
        {
            id: 7,
            level: 1,
            path: 'delivery-ticket/physical',
            title: "Entrega de tickets Fisico",
            icon: "bookmark",
            type: "link",
            permissions:'organization-page-delivery_physical',
        },        
    ]
};
